body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.my.active {
  border-top: #FFFFFF;
  border-right: #FFFFFF;
  border-left: #FFFFFF;
  border-radius:3px;
  border-bottom: 6px solid #6c757d;
}