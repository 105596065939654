.confirmMain section p {
    margin-top: 10px;
    margin-bottom: 0;
}

.confirmMain section h4 {
    margin: 0;
}

.confirmMain section svg {
    color: #b72c2c;
    height: 55px;
    width: 55px;
    margin-bottom: 10px;
}

.confirmMain section button {

    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    color: white;
    font-weight: 500;
    margin-top: 15px;
}

.confirmMain section button.cancel {
    background-color: #4ca4a4;
}

.confirmMain section button.cancel:hover {
    background-color: #3b7f7f;
}

.confirmMain section button.delete:hover {
    background-color: #b72c2c;
}

.confirmMain section button.delete {
    margin-left: 10px;
    background-color: #d44b4b;

}

.confirmMain section {
    min-width: 310px;
    align-items: center;
    margin: 20px;
    background-color: #e9f5fe;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    border: 1.5px solid #0000001c;
    box-shadow: 0 0 20px -10px #0000002b;
}

.confirmMain {
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: absolute;
    inset: 0;
    display: flex;
}