.notFoundDiv {
    background-color: #f8f9fa;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notFoundDiv main h1 {
    font-weight: 300;
}

.notFoundDiv main h4 {
    padding-top: 10px;
    font-weight: 500;
}

.notFoundDiv main {
    margin: 20px;
    background-color: #274050;
    min-width: 20vw;
    color: #f8f9fa;
    border-radius: 20px;
    padding: 55px;
    text-align: center;
}

@media (max-width: 576px) {

    .notFoundDiv main {
        padding: 20px;

    }
}