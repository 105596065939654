.navbarDiv a {
    color: #7299b6;
}

.navbarDiv {
    background-color: #f8f9fa;
    color: #7299b6;
    height: 85px;
    z-index: 1000;
    box-shadow: 0px 0px 20px #01297040;

}