.profileDrop main svg:hover {

    color: #b5e7e7;
    
}

.profileDrop main svg {
    color: #ffffff;
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.profileDrop {
    position: relative;
}

.profileDrop main {
    margin: 0 10px;
}


.profileDrop section svg {
    color: black;

}

.profileDrop section li:hover {

    background-color: #d8cccc;
}

.profileDrop section li.myBtn {
    display: flex;
    justify-content: center;
    align-items: center;

}

.profileDrop section li {
    background-color: #efe8e8;
    list-style: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 5px 0;
}

.profileDrop section {
    padding: 15px;
    /* display: flex; */
    justify-content: center;
    position: absolute;
    width: 255px;
    z-index: 300;
    top: 125%;
    background-color: #f8f9fa;
    border-radius: 10px;
    /* border: 1.5px solid #274050; */
    box-shadow: 1px 5px 10px -3px currentColor;
}