.my-border {
    border: 1px solid #dee2e6;
}

.my-border-right {
    border-right: 1px solid #dee2e6;
}

.my-border-top {
    border-top: 1px solid #dee2e6;
}

.my-border-left {
    border-left: 1px solid #dee2e6;
}

.my-border-bottom {
    border-bottom: 1px solid #dee2e6;
}


.sidebar {
    position: absolute;
    top: 56px;
    left: 0px;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    /* padding: 20px; */
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

.main {
    margin-left: 300px;
}

* {

    transition: all 0.3s;
}

/* css for small width */
@media (max-width: 992px) {
    .main {
        margin-left: 0;
    }

    #sidebar {
        left: -300px
    }

    .asidebarclick {
        display: block;
    }

}

@media (min-width: 992px) {
    #sidebar {
        left: 0px !important
    }
}



/* css for large width */
/* @media (min-width: 576px) {}

.showHideAsideBar {
    left: -300px;
} */
.selectedVisitor span {
    display: none !important;

}

.selectedVisitor {
    background-color: rgb(239 232 232);
}

.overflowContainer {
    overflow-y: auto;
    max-height: 100%;
    overflow-x: hidden;
}

.visitorLi .newMessage {
    background-color: #7299b8;
    border-radius: 50%;
    color: white;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    padding: 10px;
}

.visitorLi li .my-color {
    padding: 25px 15px 25px 15px;
}

.visitorLi li {
    list-style: none;
    cursor: pointer;
    width: 100%;
}

.visitorLi li:hover {
    background-color: rgb(239, 232, 232);
}

.messageDiv {
    overflow-wrap: anywhere;
}

.boxShadowAbove::after {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    box-shadow: 0 130px 103px -155px #7299b6 inset;
    inset: 0;
    height: 100px;
}