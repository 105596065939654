:root {
    /* --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #e9f5fe;
    --right-msg-bg: #98d0fa;
}

html {
    box-sizing: border-box;
}

/* *,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
} */

/* // body {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     background-image: var(--body-bg);
//     font-family: Helvetica, sans-serif;
// } */

.msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: var(--border);
    border-radius: 5px;
    background: var(--msger-bg);
    /* box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2); */
}

.msger-header {
    /* display: flex; */
    font-size: medium;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
    border-bottom: var(--border);
    background: #2c62ad;
    color: rgb(255, 255, 255);
}

.msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px 20px 10px 20px;
    font-size: 15px;
}

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 450px;
    padding: 8px 15px;
    border-radius: 15px;
    background: var(--left-msg-bg);
}

.msg-info {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 13px;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
}

.msg-info-time {
    font-size: 0.85em;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: #5b3fff;
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

/* .msger-inputarea {
    display: flex;
    padding: 10px;
    border-top: var(--border);
    background: #e9f5fe; 
} 

 .msger-inputarea * {
     padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em; 
}  */

.msger-input {
    flex: 1;
    /* background: #ddd; */
}



.msger-send-btn {
    margin-left: 10px;
    background: rgb(0, 196, 65);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
}

.msger-send-btn:hover {
    background: rgb(0, 180, 50);
}

/* .msg-text {
} */
.floatBtn{
    height:50px;
    width:50px;
    border-radius:50%;
}