.adminAside {
    position: fixed;
    top: 85px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);
    background-color: #fff;
}

.adminAside main ul .mainProfile .imgContainer img {
    height: 100%;
    width: 100%;
}

.adminAside main ul .mainProfile .imgContainer {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
}

.adminAside main ul .mainProfile p {
    margin: 0;
    font-size: 15px;
}

.adminAside main ul .mainProfile h6 {
    margin: 0;
}

.adminAside main ul .mainProfile {
    padding: 10px 18px;
    background-color: aqua;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.adminAside main ul {
    margin: 0;
    padding: 0;
}

.adminAside main ul li a {
    color: black;
    text-decoration: none;
}

.adminAside main ul li:hover {
    /* background-color: #cfd8df; */
    background-color: #f8f9fa;
    cursor: pointer;
    box-shadow: 2px 2px 5px 0px #01297040;

}

.adminAside main ul li {
    /* background-color: #e5eaee; */
    list-style: none;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.adminAside main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
}

.adminMain {
    margin-left: 300px;
    padding: 20px;
}

.toggleBtn main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.toggleBtn main svg {
    height: 15px;
    width: 15px;
    stroke-width: 2px;
    stroke: #00000075;

}

.toggleBtn:hover {
    box-shadow: inset -5px 0 10px #26accd;
}

.toggleSideBar .adminAside {
    /* width: 0 !important; */
    margin-left: -300px !important;
    box-shadow: none;
}

.toggleSideBar .adminMain {
    margin-left: 0;
}

.toggleSideBar .toggleBtn {
    left: calc(0px - 35px/2);
}

.toggleSideBar .toggleBtn main svg {
    transform: rotate(180deg);
    margin-left: 12px;
}

.toggleBtn {
    cursor: pointer;
    height: 35px;
    width: 35px;
    background-color: #f8f9fa;
    top: 122px;
    left: calc(300px - 35px/2);
    position: absolute;
    z-index: 10000;
    border-radius: 50%;
    box-shadow: inset -5px 0 10px #00ffff;
    border: none;
}

.adminMain input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 20px;
    appearance: none;
    background-color: c6c6c6;
    border-radius: 20px;
    transition: .5s;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
}

.adminMain input:checked[type="checkbox"] {
    background-color: #00ffff;
}

.adminMain input[type="checkbox"]::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background-color: #fff;
    transform: scale(1.15);
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    transition: .3s;

}

.adminMain input:checked[type="checkbox"]::before {
    left: 20px;

}



@media (max-width: 992px) {
    .adminMain {
        margin-left: 0 !important;
    }

    .adminAside {
        /* width: 0 !important; */
        margin-left: -300px !important;
        box-shadow: none;
    }

    .adminMain {
        margin-left: 0;
    }

    .toggleBtn {
        left: calc(0px - 35px/2);
    }

    .toggleBtn main svg {
        transform: rotate(180deg);
        margin-left: 12px;
    }
    .toggleSideBar .adminAside {
        /* width: 0 !important; */
        margin-left: 0 !important;
        box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);

    }

    .toggleSideBar .adminMain {
        margin-left: 0;
    }

    .toggleSideBar .toggleBtn {
        left: calc(300px - 35px/2);
    }

    .toggleSideBar .toggleBtn main svg {
        transform: rotate(0deg) !important;
        margin-left: 0;
    }



}