/* CSS for stepper from seconds page */

.progressbar {
    counter-reset: step;
  }
  
  .progressbar li {
    list-style-type: none;
    float: left;
    width: 30%;
    position: relative;
    text-align: center;
  }
  
  .progressbar li:before {
    /* content: counter(step);
    counter-increment: step; */
    /* border: solid 2px #94969c; */
    border-radius: 50%;
    height: 41px;
    width: 41px;
    line-height: 37px;
    display: block;
    text-align: center;
    margin: auto;
    background-color: white;
  
    border: white;
    background-image: url('../images/exclamation-lg.svg');
    background-size: 28px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    content:'';
      background-color: #f8f9fa;
  }
  
  .progressbar li:after {
    background-color: #94969c;
    height: 2%;
    width: 83%;
    top: 20px;
    content: '';
    position: absolute;
    left: 58%;
    z-index: -1;
  }
  
  .progressbar li:last-child::after {
    content: none;
    /* left:-50%; */
  }
  
  .progressbar li.compelete {
    color: black;
  }
  
  .progressbar li.compelete:before {
    border-color: #b5cb25;
    background-color: #b5cb25;
  }
  
  .progressbar li.compelete::after {
    background-color: #b5cb25;
  }
  .progressbar li.active {
    color: black;
  }
  
  .progressbar li.active:before {
    content: '';
    border-color: #b5cb25;
    background-color: #b5cb25;
    background-image: url('../images/check-all.svg');
    background-size: 25px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  
  
  }
  
  .progressbar li.active::after {
    background-color: #b5cb25;
  }
  .progressbar li.notDone::before {
    border:2px solid  #b5cb25;
    /* background-image: url('exclamation-lg.svg');
    background-size: 28px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    content:''; */
  
  
  }
  
  /* End of-- CSS for stepper from seconds page */
  
  